<script setup lang="ts">
import { defineProps, withDefaults, computed, defineAsyncComponent } from "vue";
import { type DataObjectProgress } from "o365.pwa.modules.client.steps.DataObjectProgress.ts";
import { SyncStatus } from "o365.pwa.modules.client.steps.StepSyncProgress.ts";
import DataObjectProgressOffline from "o365.pwa.vue.components.steps.DataObjectProgressOffline.vue";
import DataObjectProgressOnline from "o365.pwa.vue.components.steps.DataObjectProgressOnline.vue"; 

interface IProps {
  syncStepProgress: DataObjectProgress;
  currentStep: boolean;
  expanded: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
  currentStep: false,
});

</script>



<script lang="ts">
  export default {
    name: "DataObjectProgress",
  };
</script>

<template>
    <DataObjectProgressOffline
      v-if="props.syncStepProgress.syncType === 'OFFLINE-SYNC'"
      :syncStepProgress="props.syncStepProgress"
      :currentStep="props.currentStep"
      :expanded="props.expanded"
    />
    <DataObjectProgressOnline
      v-else-if="props.syncStepProgress.syncType === 'ONLINE-SYNC'"
      :syncStepProgress="props.syncStepProgress"
      :currentStep="props.currentStep"
      :expanded="props.expanded"
    />
</template>

